<template>
	<div>
		<div class="tw-max-w-full tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8">
			<div class="tw-flex tw-justify-between tw-h-12">
				<div class="tw-flex tw-w-1/5">
					<div class="tw-flex-shrink-0 tw-flex tw-items-center">
						<a
							:href="homeUrl"
							title="A Software Revolution!"
						>
							<img
								:src="logoPath"
								alt="Ortus Solutions Corp"
								class="tw-block lg:tw-hidden tw-h-8 tw-w-auto"
							>
							<img
								:src="logoPath"
								alt="Ortus Solutions Corp"
								class="tw-hidden lg:tw-block tw-h-8 tw-w-auto"
							>
						</a>
					</div>
				</div>

				<div
					id="mainnav"
					class="mainnav tw-hidden lg:tw-flex tw-justify-center tw-items-center tw-w-3/5"
					v-if="menu.length"
				>
					<ul
						class="menu tw-block lg:tw-flex lg:tw-space-x-2 lg:tw-justify-end tw-list-none"
					>
						<li class="tw-items-start lg:tw-items-center lg:tw-text-center">
							<a
								:href="homeUrl"
								role="button"
								class="tw-text-gray-500"
							>
								<i
									class="fas fa-home"
									aria-label="Home"
								/>
							</a>
						</li>
						<template v-for="item in menu">
							<flyout-menu-item
								:key="item.title"
								:label="item.title"
								:items="item.subpagemenu"
								menu-item-class=""
								:overview-link="item.link"
								:columns="1"
								:is-secondary-menu="false"
							/>
						</template>
						<li class="tw-items-start lg:tw-items-center lg:tw-text-center">
							<a
								href="/blog"
								role="button"
								class="tw-text-gray-500 border-ortus hover:tw-text-gray-900"
							>
								Blog
							</a>
						</li>
					</ul>
				</div>

				<!-- Main contact us -->
				<div
					class="mainnav tw-hidden lg:tw-flex tw-space-x-4 lg:tw-space-x-5 tw-justify-end tw-items-center tw-w-1/5"
				>
					<ul
						class="menu tw-block lg:tw-flex lg:tw-space-x-3 lg:tw-justify-end tw-items-center tw-list-none"
					>
						<template v-for="item in secondaryMenu">
							<flyout-menu-item
								:key="item.label"
								:label="item.label"
								:items="getSubMenuItems( item )"
								menu-item-class="tw-text-gray-300 tw-text-lg tw-list-none"
								:overview-link="item.url"
								:columns="1"
								:is-secondary-menu="true"
								:item-data="item.data"
							/>
						</template>
						<lang-nav
							v-if="langMenu && langMenu.length"
							tag="li"
							:menu="langMenu"
							icon-class="fa fa-globe tw-text-white lg:tw-text-gray-500 border-ortus"
							close-icon-class="fas fa-times"
						/>
						<li>
							<a
								id="btnSearch"
								data-toggle="collapse"
								href="#universalSearch"
								role="button"
								aria-expanded="false"
								aria-controls="universalSearch"
								data-placement="bottom"
								title="Site Search"
								class="nav-item-link"
							>
								<i
									class="fas fa-search tw-text-lg tw-text-gray-400"
									aria-label="Search"
								/>
							</a>
						</li>
					</ul>
				</div>

				<!-- Mobile menu icon -->
				<div class="tw--mr-2 tw-flex tw-items-center lg:tw-hidden">
					<button
						@click="openMenu"
						type="button"
						class="tw-inline-flex tw-items-center tw-justify-center tw-p-2 tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-500"
					>
						<i class="fas fa-bars tw-text-xl" />
					</button>
				</div>
			</div>
		</div>

		<!-- Mobile Menu -->
		<div
			id="mobile-menu"
			class="lg:tw-hidden tw-bg-blue-900"
			v-show="isShow"
		>
			<div class="pt-2 pb-3">
				<div
					id="mainnav"
					class="mainnav lg:tw-hidden container tw-pt-12 tw-pb-5"
					v-if="menu.length"
				>
					<ul
						class="menu tw-flex tw-space-y-6 tw-flex-col tw-list-none"
					>
						<li class="tw-items-start lg:tw-items-center lg:tw-text-center">
							<a
								:href="homeUrl"
								role="button"
								class="tw-px-3 tw-font-bold tw-w-full tw-flex"
								:class="currentPathName == '/' ? 'tw-text-blue-900 tw-bg-white tw-rounded-md tw-py-2' : 'tw-text-white'"
							>
								Home
							</a>
						</li>
						<template v-for="item in menu">
							<flyout-menu-item
								:key="item.title"
								:label="item.title"
								:items="item.subpagemenu"
								menu-item-class=""
								:overview-link="item.link"
								:columns="1"
								:is-mobile="true"
								:is-secondary-menu="false"
							/>
						</template>
						<li class="tw-items-start lg:tw-items-center lg:tw-text-center">
							<a
								href="/blog"
								role="button"
								class="tw-px-3 tw-font-bold tw-flex tw-w-full"
								:class="currentPathName == '/blog' ? 'tw-text-blue-900 tw-bg-white tw-rounded-md tw-py-2' : 'tw-text-white'"
							>
								Blog
							</a>
						</li>
					</ul>
				</div>
				<div class="tw-pt-4 tw-pb-3 tw-border-t tw-border-gray-200">
					<ul class="lg:tw-hidden tw-flex tw-space-x-7 tw-justify-start tw-items-center tw-mx-6 tw-list-none">

						<template v-for="item in secondaryMenu">
							<flyout-menu-item
								:key="item.label"
								:label="item.label"
								:items="getSubMenuItems( item )"
								menu-item-class="tw-text-white tw-text-lg tw-list-none"
								:overview-link="item.url"
								:columns="1"
								:is-secondary-menu="true"
								:item-data="item.data"
								:is-mobile="true"
							/>
						</template>
						<lang-nav
							v-if="langMenu && langMenu.length"
							tag="li"
							:menu="langMenu"
							icon-class="fa fa-globe tw-text-white lg:tw-text-gray-500 border-ortus tw-text-lg"
							close-icon-class="fas fa-times"
						/>
						<li>
							<a
								href="#generalSearchSection"
								role="button"
								class="tw-bg-transparent tw-font-bold"
								data-toggle="collapse"
								aria-expanded="false"
								aria-controls="generalSearchSection"
							>
								<i
									class="fas fa-search tw-text-white xl:tw-text-gray-400 tw-text-xl"
									aria-label="Search"
								/>
							</a>
						</li>
					</ul>
				</div>
			</div>

			<!-- Mobile General search -->
			<div
				id="generalSearchSection"
				class="lg:tw-hidden collapse tw-bg-white"
				aria-expanded="true"
				:class="{ 'tw-hidden' : !isOpen }"
			>
				<div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-screen tw-h-32">
					<form
						id="mobileFormSearch"
						action="/__search"
						method="POST"
					>
						<div class="tw-relative tw-text-gray-500 focus-within:tw-text-gray-400">
							<span class="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-2">
								<button
									type="button"
									class="tw-p-1 focus:tw-outline-none focus:tw-shadow-outline"
									@click="submitSearch"
								>
									<i
										aria-label="Search"
										class="fas fa-search tw-text-xl"
									/>
								</button>
							</span>
							<input
								id="q"
								type="search"
								name="q"
								v-model="searchTerm"
								placeholder="Search..."
								autocomplete="off"
								class="tw-w-80 tw-py-2 tw-text-sm tw-rounded-md tw-pl-10 focus:tw-outline-none tw-bg-gray-300 tw-border-none"
							>
							<span class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
								<button
									type="button"
									class="tw-p-1 focus:tw-outline-none focus:tw-shadow-outline"
									onclick="document.getElementById( 'q' ).value = ''"
								>
									<i
										class="fas fa-trash-alt tw-text-xl"
										aria-label="Delete"
									/>
								</button>
							</span>
						</div>
					</form>
					<div class="text-center tw-space-y-3">
						<button
							type="button"
							class="tw-bg-transparent tw-border-0 tw-p-1"
							data-toggle="collapse"
							href="#generalSearchSection"
							role="button"
							aria-expanded="false"
							aria-controls="generalSearchSection"
							onclick="document.getElementById( 'mobileGeneralSearch' ).value = ''"
						>
							<i
								aria-label="Close"
								class="fas fa-times-circle tw-text-3xl tw-text-red-400"
							/>
						</button>
						<button
							type="button"
							class="tw-bg-transparent tw-border-0 tw-p-1"
							@click="submitSearch"
						>
							<i
								aria-label="Confirm"
								class="fas fa-check-circle tw-text-3xl tw-text-green-400"
							/>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import menusApi from "../api/menus";
import FlyoutMenuItem from "./FlyoutMenuItem.vue";

export default {
	components : { FlyoutMenuItem },
	props      : {
		initialMenu  : { type: Array },
		initialSecondaryMenu : { type: Object },
		initialLangMenu : { type: Object },
		logoPath     : { type: String },
		logoIconPath : { type: String },
		homeUrl 	 : { type: String }
	},
	data() {
		return {
			menu       : [],
			secondaryMenu : [],
			langMenu : [],
			isOpen     : false,
			isShow     : false,
			topOffset  : 0,
			searchTerm : ""
		};
	},
	created(){
		if ( this.initialMenu ){
			Vue.set( this, "menu", this.keysToLowerCase( this.initialMenu ) );
		} 
		
		if ( this.initialSecondaryMenu && this.initialSecondaryMenu.menuItems ){
			Vue.set( this, "secondaryMenu", this.keysToLowerCase( this.initialSecondaryMenu.menuItems ) );
		}
		if ( this.initialLangMenu && this.initialLangMenu.menuItems ){
			Vue.set( this, "langMenu", this.keysToLowerCase( this.initialLangMenu.menuItems ) );
		}
	},
	computed : {
		currentPath() {
			return window.location.href;
		},
		currentPathName() {
			return window.location.pathname;
		}
	},
	methods : {
		submitSearch(){
			document.getElementById( "q" ).innerHTML = this.plainText( this.searchTerm );
			document.getElementById( "mobileFormSearch" ).submit();
		},
		plainText( text ) {
			return text.replace( /<[^>]+>/g, "" );
		},
		/**
         * Ensures that the keys of an object or an array of objects ( menu tree ) are in lowercase
         * @param {Any} obj - Object, or array of objects
         */
		keysToLowerCase( obj ) {
			// if menu level is an array
			if ( obj instanceof Array ) {
				obj = obj.map( ( i ) => this.keysToLowerCase( i ) );
			}
			// if obj is simple value type, return
			if ( !typeof( obj ) === "object" || typeof( obj ) === "string" || typeof( obj ) === "number" || typeof( obj ) === "boolean" ) {
				return obj;
			}

			// get object keys
			var keys = Object.keys( obj );
			var n = keys.length;
			var lowKey;
			while ( n-- ) {
				var key = keys[ n ];
				// convert object key to lowercase
				if ( key === ( lowKey = key.toLowerCase() ) )
					continue; // key is already lowercase, continue to next
				// assign value to new lowercase key
				obj[ lowKey ] = this.keysToLowerCase( obj[ key ] );
				// delete uppercase key item
				delete obj[ key ];
			}
			return ( obj );
		},
		/**
         * Fetches the menu
         */
		fetchMenu(){
			var params = {
				"excludes" : "home,home 2019",
				"levels"   : 2
			};
			menusApi.list( params )
				.then( ( result ) => {
					this.$set( this, "menu", result.data.data );
				} )
				.catch( ( e ) => {
					console.error( e );
				} );
		},
		toggleMenu(){
			if ( this.$refs.mainNav.classList.contains( "open" ) ){
				this.closeMenu();
				// this.$refs.mainNav.style.paddingTop = this.topOffset + "px";
			} else {
				this.$refs.mainNav.classList.add( "open" );
				this.isOpen = true;
			}
		},
		closeMenu(){
			this.$refs.mainNav.classList.remove( "open" );
			this.isOpen = false;
		},
		openMenu(){
			this.isShow = !this.isShow;
		},
		getSubMenuItems( data ){
			if( data.menutype == "SubMenu" ){
				return data.submenu.menuitems.length ? data.submenu.menuitems : [] 
			}
			return [];
		}
	}
};
</script>