<template>
	<div>
		<template v-if="events.length">
			<div class="container tw-text-4xl tw-mb-5">
				<ul
					class="nav tw-flex-wrap tw-m-0 tw-flex tw-justify-center tw-items-center tw-text-sm tw-text-blue-800"
					role="tablist"
				>
					<li
						v-for="( year, index ) in years"
						:key="index"
					>
						<button
							class=" tw-cursor-pointer tw-mx-2 tw-py-2 tw-px-6 tw-rounded-md tw-font-normal tw-text-lg hover:tw-bg-blue-900 hover:tw-text-white"
							:class="[ selectedYear === year ? 'tw-text-white tw-bg-blue-900' : '' ]"
							@click="selectYear( year )"
							role="tab"
							:aria-selected="selectedYear === year"
							:aria-controls="'panel' + selectedYear"
						>
							{{ year }}
						</button>
					</li>
				</ul>
				<div
					class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 tw-mt-10"
					:id="'panel' + selectedYear"
					:ref="'panel' + selectedYear"
					role="tabpanel"
					tabindex="0"
					:aria-label="selectedYear + ' Events'"
				>
					<div
						class="tw-flex tw-justify-center"
						v-for="event in selectedEvents"
						:key="event.contentID"
					>
						<a
							:href="event.slug"
							class="tw-transition tw-duration-400 tw-ease-in-out tw-transform hover:tw--translate-y-1 hover:tw-scale-105 tw-text-black hover:tw-text-black hover:tw-no-underline"
						>
							<div v-if="event.featuredImageURL">
								<img
									class=" tw-rounded-md"
									:src="event.featuredImageURL"
									:alt="event.title + ' featured image'"
								>
							</div>
							<div class=" tw-text-lg tw-font-black">
								{{ event.title }}
							</div>
							<div class=" tw-text-sm">
								{{ getCustomField( event, 'eventDates' ) }}
							</div>
							<div class=" tw-text-sm">
								{{ getCustomField( event, 'location' ) }}
							</div>
						</a>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="tw-text-center">
				<p class="tw-text-gray-700 tw-text-3xl">
					<i
						class="far fa-frown"
						aria-hidden="true"
					/>
					There are no archived events.
				</p>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props : {
		events : {
			type     : Array,
			required : true
		}
	},
	data() {
		return {
			years          : [],
			selectedEvents : [],
			selectedYear   : ""
		};
	},
	methods	: {
		/**
		* Change year selected value and focus its respective events panel when using tab
		* @param {Number} year Year value to select
		*/
		selectYear( year ) {
			this.selectedYear = year;
			this.selectEventsByYear( year );
			this.$nextTick( () => {
				let panelId = "panel" + year;
				this.$refs[panelId].focus();
			} );
		},
		/**
		* Filters list of events to show by passing a year value.
		* @param {Number} year Year value to use on filter
		*/
		selectEventsByYear( year ) {
			this.selectedEvents = this.events.filter( ( item ) => {
				let showDate = item.customFields.find( ( field ) => field.key === "showDate" );
				
				if ( showDate ){
					return ( new Date( showDate.value ) ).getFullYear() === year;
				}

				return false;
			} );
		},
		/**
		* Get customfield from an object, returns empty string when it doesn't exists
		* @param {Object} obj The object containing the customfield
		* @param {String} fieldName The name of the custom field
		*/
		getCustomField( obj, fieldName ) {
			let field = obj.customFields.find( ( field ) => field.key === fieldName );
			return field != undefined ? field.value : "";
		}
	},
	mounted(){
		const filteredYears = [];
		this.events.forEach( ( item ) => {
			let showDate = item.customFields.find( ( field ) => field.key === "showDate" );
		
			if( showDate ){
				filteredYears.push( ( new Date( showDate.value ) ).getFullYear() );
			}

		} );

		this.years = [ ...new Set( filteredYears ) ].reverse();
		this.selectedYear = this.years[0];
		this.selectEventsByYear( this.selectedYear );
	}
};
</script>