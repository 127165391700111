<template>
	<component
		:is="tag"
		class="bx-langnav"
		ref="langnav"
	>
		<button
			role="button"
			class="bx-langnav__trigger"
			aria-controls="bxLangnavMenu"
            :aria-expanded="showMenu"
			@click="toggleMenu()"
		>
			<i
				v-if="iconClass != ''"
				:class="iconClass"
				aria-label="Language"
			/>
			<span v-else>Language</span>
		</button>
		<div
			id="bxLangnavMenu"
			ref="langnavMenu"
			class="bx-langnav__menu bx-langnav__menu--end"
			:class="[ showMenu ? 'show' : '', isHiding ? 'hiding' : '']"
		>
			<div class="bx-menu__header">
				<button
					type="button"
					class="btn-close"
					@click="closeMenu()"
					aria-label="Close"
				>
					<i
						v-if="closeIconClass"
                        :class="closeIconClass"
						aria-hidden="true"
					/> Close
				</button>
			</div>
			<div class="bx-menu__body">
				<ul>
					<li
						v-for="( item, itemIdx ) in menu"
						:key="'sub-' + itemIdx"
					>
						<a :href="item.url">
							{{ item.label }}
						</a>
					</li>
				</ul>
			</div>
		</div>
	</component>
</template>

<script>
export default {
	props : {
	    tag : {
			type    : String,
			default : "div"
		},
		menu : {
			type    : Array,
			default : function(){ return []; }
		},
		iconClass : {
			type    : String,
			default : ""
		},
		closeIconClass : {
			type    : String,
			default : ""
		}
	},
	data() {
		return { showMenu: false, isHiding: false };
	},
	mounted() {
		var self = this;
		document.body.addEventListener( "click", this.handleOutsideClick );
		this.$refs.langnavMenu.ontransitionstart = ( event ) => {
			if ( event.propertyName == "transform" && self.showMenu == false ) {
				self.isHiding = true;
			}
		};
		this.$refs.langnavMenu.ontransitionend = ( event ) => {
			if ( event.propertyName == "transform" && self.showMenu == false ) {
				self.isHiding = false;
			}
		};
	},
	methods : {
		closeMenu() {
			this.showMenu = false;
		},
		handleOutsideClick( event ) {
			if ( this.showMenu && !this.$refs.langnav.contains( event.target ) ) {
				this.closeMenu();
			}
		},
		toggleMenu() {
			this.showMenu = !this.showMenu;
		}
	}
};
</script>